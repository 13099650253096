import type { NextPage } from 'next';
import type { FunctionComponent } from 'react';

export type Provider<P = any> = FunctionComponent<P>;

export type ProviderWithProps<P = any> = [Provider<P>, P];

export type Providers = (Provider | ProviderWithProps)[];

export type NextPageWithProviders<P = {}, IP = P> = NextPage<P, IP> & {
  providers?: Providers;
};

export const Providers: FunctionComponent<{ providers: Providers }> = ({
  providers,
  children,
}) => (
  <>
    {providers.reduceRight((result, current) => {
      const isProviderWithProps = current instanceof Array;
      const [Provider, props] = isProviderWithProps ? current : [current, {}];
      return <Provider {...props}>{result}</Provider>;
    }, children)}
  </>
);
