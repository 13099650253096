import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const Avatar: ComponentStyleConfig = {
  baseStyle: {
    container: {
      boxShadow: 'md',
    }
  },
  sizes: {
    md: {
      container: {
        width: '36px',
        height: '36px',
      },
      label: {
        fontSize: '12px'
      }
    },
  },
  defaultProps: {
    size: 'md',
  },
};

const theme = extendTheme({
  components: {
    Avatar,
  },
});

export { theme };
