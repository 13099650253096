const sendEmailVerification = async (userId: string) => {
  const response: any = await fetch('/auth/verification-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  });

  return await response.json();
};

const refreshSession = async () => {
  const response = await fetch('/auth/refresh-session', { method: 'POST' });
  return await response.json();
};

const isEmailVerified = async (userId: string) => {
  const response = await fetch(`/auth/email-verified/${userId}`);

  return await response.json();
};

export const authService = {
  sendEmailVerification,
  refreshSession,
  isEmailVerified,
};
