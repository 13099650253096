import { useRouter } from "next/router";
import { useMemo } from "react";
import { getRoute, getUrl } from "~/utils/url";

export enum TeamTabs {
  YOUR_TEAMS = "YOUR_TEAMS",
  ALL_TEAMS = "ALL_TEAMS",
}

export enum TeamDetailsTabs {
  TEAM_DETAILS = "TEAM_DETAILS",
  MANAGE_MEMBERS = "MANAGE_MEMBERS",
}

export enum CoworkerTabs {
  FOLLOWING = "FOLLOWING",
  ALL = "ALL",
  FOLLOWERS = "FOLLOWERS",
}

export enum CommunityTabs {
  TEAMS = "TEAMS",
  PEOPLE = "PEOPLE",
}

export const appRoutes = {
  home: () => "/home",
  account: () => "/home/account",
  payments: () => getRoute("/home/account/payments"),
  notifications: () => getRoute("/home/account/notifications"),
  privacy: () => getRoute("/home/account/privacy"),
  integrations: () => getRoute("/home/account/integrations"),
  addCard: () => "/home/account/payments/add-card",
  profile: () => getRoute("/home/account/profile"),
  profileDetails: (id: string) => getRoute(`/profile/${id}`),
  onboardingNotifications: () => "/onboarding/notifications",
  contactRequestSent: (workspaceName: string, resourceName: string) =>
    `/contact-request-sent?workspaceName=${workspaceName}&resourceName=${resourceName}`,

  saved: () => "/saved",
  search: () => getUrl("/search"),
  workspaceDetails: (id: string) => getRoute(`/workspace/${id}`),
  book: () => getRoute("/book"),
  searchWizard: () => getRoute("/search/wizard"),

  community: (tab: CommunityTabs) => `/community?tab=${tab}`,
  coworkers: (tab: CoworkerTabs) => `/community/network/coworkers?tab=${tab}`,
  teams: (tab: TeamTabs) => `/community/network/teams?tab=${tab}`,
  teamCreate: () => "/community/network/teams/create",
  teamEdit: (tab: TeamDetailsTabs, teamId: string) =>
    `/community/network/teams/edit/${teamId}?tab=${tab}`,
  teamDetails: (teamId: string) => `/community/network/teams/${teamId}`,
};

export const useNavigator = () => {
  const router = useRouter();

  return useMemo(() => {
    const {
      push,
      query,
      locale,
      asPath,
      basePath,
      events,
      pathname,
      replace,
      back,
      isReady,
    } = router;

    return {
      isReady,
      replace,
      back,
      goBack: (previousUrl?: string) => {
        if (!!previousUrl) {
          push(previousUrl);
          return;
        }
        back();
      },
      pathname,
      navigateTo: push,
      navigateWithQuery: (url: string) => push(getRoute(url)),
      queryData: {
        newWindow: query.newWindow as string,
        returnTo: query.returnTo as string,
        profileId: query.profileId as string,
        workspaceId: query.workspaceId as string,
        resourceId: query.resourceId as string,
        workspaceName: query.workspaceName as string,
        resourceName: query.resourceName as string,
        resourcePreviewId: query.resourcePreviewId as string,
        startTime: query.startTime as string,
        endTime: query.endTime as string,
        time: query.time as string,
        date: query.date as string,
        tab: query.tab as
          | CoworkerTabs
          | TeamDetailsTabs
          | TeamTabs
          | CommunityTabs,
        teamId: query.teamId as string,
      },
      locale,
      asPath,
      basePath,
      events,

      goToHome: () => push(appRoutes.home()),
      goToCommunity: (tab: CommunityTabs) => push(appRoutes.community(tab)),
      goToSaved: () => push(appRoutes.saved()),
      goToTeams: (tab: TeamTabs) => push(appRoutes.teams(tab)),
      goToCoworkers: (tab: CoworkerTabs) => push(appRoutes.coworkers(tab)),
      goToTeamEdit: (tab: TeamDetailsTabs, teamId: string) =>
        push(appRoutes.teamEdit(tab, teamId)),
      goToTeamDetails: (teamId: string) => push(appRoutes.teamDetails(teamId)),
      goToProfileDetails: (id: string) => push(appRoutes.profileDetails(id)),
      goToWorkspaceDetails: (id: string) =>
        push(appRoutes.workspaceDetails(id)),
      goToOnboardingNotifications: () =>
        push(appRoutes.onboardingNotifications()),
      goToContactRequestSent: (workspaceName: string, resourceName: string) =>
        push(appRoutes.contactRequestSent(workspaceName, resourceName)),
      goToSearch: () => push(appRoutes.search()),
      goToAddCard: () => push(appRoutes.addCard()),
    };
  }, [router]);
};
