import React from "react";
import { useApollo } from "~/lib/apollo-client";
import { ApolloProvider } from "@apollo/client";

interface ApolloContextProviderProps {
  pageProps: any;
}

export const ApolloContextProvider: React.FC<ApolloContextProviderProps> = ({
  children,
  pageProps,
}) => {
  const apolloClient = useApollo(pageProps);
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
