import { TheTime } from "~/_libs/time/TheTime";
import { Timespan } from "~/_libs/time/Timespan";
import { BookingUnitMode } from "~/types/BookingUnitMode";
import { Resource } from "~/types/Resource";

export const preselectRangeTime = (resource: Resource): Timespan => {
  const { bookingUnitMode } = resource;

  const isRanged = bookingUnitMode === BookingUnitMode.BOOKING_UNIT_MODE_RANGES;

  if (!isRanged) {
    return null;
  }

  const { rangeBookingUnitList } = resource;
  const availableRanges = rangeBookingUnitList?.filter(
    (range) => !range.isUnavailable,
  );
  const hasOnlyOne = availableRanges?.length === 1;

  if (!hasOnlyOne) {
    return null;
  }

  const range = availableRanges[0];
  const fromDate = new TheTime(range.startTime.hour, range.startTime.minute);

  return {
    fromTime: fromDate,
    toTime: fromDate.addMinutes(range.durationInMinutes),
  };
};
