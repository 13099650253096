
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "../styles/globals.css";

import type { AppProps } from "next/app";

import Head from "next/head";
import { useMemo } from "react";
import ErrorBoundary from "~/components/errors/ErrorBoundary";
import { PwaHeaders } from "~/components/headers/PwaHeaders";
import { SeoMetaTags } from "~/components/headers/SeoHeaders";
import { NextPageWithProviders, Providers } from "~/components/Providers";
import { AnalyticsProvider } from "~/contexts/Analytics/AnalyticsContext";
import { ApolloContextProvider } from "~/contexts/ApolloContext";
import { AppProvider } from "~/contexts/AppContext";
import { ComponentsStateProvider } from "~/contexts/ComponentsContext/ComponentsContext";
import { CookieConsentProvider } from "~/contexts/CookieConsent/CookieConsentContext";
import { HistoryProvider } from "~/contexts/HistoryContext";
import { AppLayout } from "~/layouts/AppLayout";
import { NextAdapter } from "next-query-params";
import { QueryParamProvider } from "use-query-params";
import { BookingFlowProvider } from "~/contexts/BookingFlow/BookingFlowContext";
import { FPStatsigProvider } from "~/contexts/RemoteConfigContext";
import { ChakraBaseProvider } from "@chakra-ui/react";
import { theme } from "../theme";
import {
  CustomerIOProvider,
  CustomerIOTracker,
} from "~/components/headers/CustomerIOTracker";
import DatadogInit from "~/components/datadog-init";
import { Toaster } from "sonner";

type AppPropsWithProviders = AppProps & {
  Component: NextPageWithProviders;
};

const FlowpassGuestApp = ({ Component, pageProps }: AppPropsWithProviders) => {
  const providers = useMemo(
    () => Component.providers ?? [],
    [Component.providers],
  );

  const seo = useMemo(() => pageProps?.seo ?? {}, [pageProps?.seo]);

  return (
    <ErrorBoundary>
      <SeoMetaTags
        description={seo.description}
        image={seo.image}
        title={seo.title}
        url={seo.url}
      />

      <Head>
        <link rel="shortcut icon" href="/guest/favicon.ico" />
      </Head>

      <CustomerIOTracker />
      <PwaHeaders />

      <AppProvider>
        <CookieConsentProvider>
          <ApolloContextProvider pageProps={pageProps}>
            <FPStatsigProvider>
              <DatadogInit />
              <AnalyticsProvider>
                <HistoryProvider>
                  <ComponentsStateProvider>
                    <CustomerIOProvider>
                      <BookingFlowProvider>
                        <ChakraBaseProvider theme={theme}>
                          <AppLayout>
                            <QueryParamProvider adapter={NextAdapter}>
                              <Providers providers={providers}>
                                <Component {...pageProps} />
                                <Toaster
                                  richColors
                                  position="top-center"
                                  toastOptions={{
                                    style: { border: "0.25px solid" },
                                  }}
                                />
                              </Providers>
                            </QueryParamProvider>
                          </AppLayout>
                        </ChakraBaseProvider>
                      </BookingFlowProvider>
                    </CustomerIOProvider>
                  </ComponentsStateProvider>
                </HistoryProvider>
              </AnalyticsProvider>
            </FPStatsigProvider>
          </ApolloContextProvider>
        </CookieConsentProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

const __Page_Next_Translate__ = FlowpassGuestApp;




// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  