import { addMinutes, intervalToDuration } from 'date-fns';

type Interval = {
  start: TheTime;
  end: TheTime;
};

export class TheTime {
  constructor(readonly hour: number, readonly minute: number) {
    if (!this.isValid(hour, minute)) {
      throw new Error(`Invalid constructor parameters: ${hour}:${minute}`);
    }
  }

  static from(date: Date): TheTime {
    if (!date) {
      return null;
    }

    const result = new TheTime(date.getHours(), date.getMinutes());
    return result;
  }

  static fromMinutes(minutes: number): TheTime {
    if (!minutes) {
      return null;
    }

    const result = new TheTime(0, 0);
    return result.addMinutes(minutes);
  }

  static parse(time: string): TheTime {
    if (!time) {
      return null;
    }

    if (time.includes('T')) {
      const [_, timePart] = time.split('T');
      const [hour, minute] = timePart.split(':').map((part) => parseInt(part));
      return new TheTime(hour, minute);
    }

    if (!time.includes(':')) {
      return new TheTime(0, 0);
    }

    const [hour, minute] = time.split(':').map((part) => parseInt(part));
    const result = new TheTime(hour, minute);
    return result;
  }

  static isWithinInterval(time: TheTime, { start, end }: Interval) {
    if (time.isEqual(start) || time.isEqual(end)) {
      return true;
    }

    return time.isAfter(start) && end.isAfter(time);
  }

  static intervalToDuration({ start, end }: Interval): Duration {
    const fakeDate = new Date();

    if (!start || !end) {
      return intervalToDuration({
        start: fakeDate,
        end: fakeDate,
      });
    }

    const dateStart = addMinutes(fakeDate, start.hour * 60 + start.minute);
    const dateEnd = addMinutes(fakeDate, end.hour * 60 + end.minute);

    return intervalToDuration({
      start: dateStart,
      end: dateEnd,
    });
  }

  static now(): TheTime {
    const date = new Date();
    return new TheTime(date.getHours(), date.getMinutes());
  }

  static areEqual(a: TheTime, b: TheTime): boolean {
    return a?.hour === b?.hour && a?.minute === b?.minute;
  }

  static startOfDay(): TheTime {
    return new TheTime(0, 0);
  }

  static endOfDay(): TheTime {
    return new TheTime(23, 59);
  }

  toString(): string {
    const formattedHour = this.hour.toString().padStart(2, '0');
    const formattedMinute = this.minute.toString().padStart(2, '0');

    const stringHour = `${formattedHour}:${formattedMinute}`;

    const regex = /^([01]?[0-9]|2[0-4]):[0-5][0-9]$/;
    if (!regex.test(stringHour)) {
      throw new Error(`Invalid time: ${stringHour}`);
    }

    return stringHour;
  }

  isAfter(other: TheTime): boolean {
    if (this.hour > other.hour) {
      return true;
    }

    if (this.hour < other.hour) {
      return false;
    }

    return this.minute > other.minute;
  }

  isEqual(other: TheTime): boolean {
    return this.hour === other.hour && this.minute === other.minute;
  }

  compare(other: TheTime): number {
    if (!other) {
      return 1;
    }

    if (this.isEqual(other)) {
      return 0;
    }

    if (this.isAfter(other)) {
      return 1;
    }

    return -1;
  }

  addMinutes(minutes: number): TheTime {
    const totalMinutes = this.hour * 60 + this.minute + minutes;
    const newHour = Math.floor(totalMinutes / 60);
    const newMinute = totalMinutes % 60;
    return new TheTime(newHour, newMinute);
  }

  private isValid = (hour: number, minute: number): boolean => {
    const isSet =
      hour !== undefined &&
      minute !== undefined &&
      hour !== null &&
      minute !== null &&
      !isNaN(hour) &&
      !isNaN(minute);

    if (!isSet) {
      return false;
    }

    if (hour === 24 && minute > 0) {
      return false;
    }

    return hour >= 0 && hour <= 24 && minute >= 0 && minute <= 59;
  };
}
