import dynamic from 'next/dynamic';
import React from 'react';
import { ErrorLayoutProps } from './ErrorLayout';

const LazyErrorLayout = dynamic<ErrorLayoutProps>(() =>
  import('./ErrorLayout').then((module) => module.ErrorLayout),
);

class ErrorBoundary extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.debug(error, errorInfo);
  }

  render() {
    if ((this.state as any).hasError) {
      return <LazyErrorLayout />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
