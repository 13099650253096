import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { from } from "@apollo/client/link/core";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import { relayStylePagination } from "@apollo/client/utilities";
import merge from "deepmerge";
import { useMemo } from "react";
import { getLogOutUrl } from "~/utils/url";
import { isEqual } from "./utils/isEqual";
import { GET_ME_QUERY } from "./gql/me/get-me.gql";
import {
  DayOccupation,
  RangeBookingUnit,
  ResourceAvailability,
} from "~/types/Resource";
import { TheInterval } from "~/_libs/time/TheInterval";
import { TheDateTime } from "~/_libs/time/TheDateTime";
import { TheTime } from "~/_libs/time/TheTime";
import { TheDay } from "~/_libs/time/TheDay";

const dayOccupationWithIntervals = (dayOccupation: DayOccupation[]) => {
  return dayOccupation.map((occupation) => ({
    ...occupation,
    interval: TheInterval.fromString(
      occupation.interval.startDate,
      occupation.interval.endDate,
    ),
  }));
};

const getUsedUpCapacity = (
  dayOccupation: DayOccupation[],
  date: TheDay,
  time?: {
    from?: string;
    to?: string;
  },
) => {
  if (!dayOccupation || dayOccupation.length === 0) {
    return 0;
  }

  if (time?.from && time?.to) {
    const from = TheDateTime.fromDateAndTime(date, TheTime.parse(time.from));
    const to = TheDateTime.fromDateAndTime(date, TheTime.parse(time.to));
    const interval = TheInterval.fromTheDateTime(from, to);
    const occupations = dayOccupationWithIntervals(dayOccupation);
    const intersections = interval.getIntersections(occupations);
    return intersections.length === 0
      ? 0
      : Math.max(...intersections.map((i) => i.count));
  }

  return Math.min(...dayOccupation.map((occupation) => occupation.count));
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors?.forEach(({ message, locations, path }) => {
      if (message.includes("FORCE_LOGOUT")) {
        if (typeof window !== "undefined") {
          console.warn("User forced to logout");
          location.replace(getLogOutUrl());
        } else {
          return "FORCE_LOGOUT";
        }
      }
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const initializeServerSideApollo = (req: {
  headers: { cookie?: string };
}) => {
  const apolloClient = createServerSideApolloClient(req);
  return apolloClient;
};
export const createMeServerSideApollo = async (req: {
  headers: { [key: string]: string | string[] } & { cookie?: string };
}) => {
  const emptyApolloClient = createEmptyApolloClient(req.headers.cookie ?? "{}");

  const { data: getMeResponse, error: getMeError } =
    await emptyApolloClient.query({
      query: GET_ME_QUERY,
      errorPolicy: "none",
    });

  if (getMeError) {
    return {
      me: getMeResponse,
      meError: getMeError,
      apolloClient: emptyApolloClient,
    };
  }

  const apolloClient = createServerSideApolloClient(req);

  apolloClient.writeQuery({
    query: GET_ME_QUERY,
    data: getMeResponse,
  });

  return { me: getMeResponse, meError: getMeError, apolloClient };
};

export function initializeClientSideApollo(pageProps: any) {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME];
  const _apolloClient = apolloClient ?? createClientSideApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export const createEmptyServerSideApolloClient = () => {
  return createEmptyApolloClient("{}");
};

const createEmptyApolloClient = (cookies: string) => {
  const adjustedUri = process.env.GQL_SERVER_DOCKER_INTERNAL;
  const httpLink = new HttpLink({
    uri: adjustedUri,
    headers: {
      Cookie: cookies,
    },
    credentials: "include",
  });

  return new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: httpLink,
    cache: createInMemoryCache(),
  });
};

const createServerSideApolloClient = (req: {
  headers: { cookie?: string };
}) => {
  const adjustedUri = process.env.GQL_SERVER_DOCKER_INTERNAL;
  const additiveLink = createAdditiveLink(
    adjustedUri,
    () => req.headers.cookie,
  );

  return new ApolloClient({
    connectToDevTools: false,
    ssrMode: true,
    link: additiveLink,
    cache: createInMemoryCache(),
  });
};

function createClientSideApolloClient() {
  const adjustedUri = process.env.NEXT_PUBLIC_GQL_SERVER;
  const additiveLink = createAdditiveLink(adjustedUri, () => "{}");

  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: false,
    link: additiveLink,
    cache: createInMemoryCache(),
  });
}

const createAdditiveLink = (adjustedUri: string, getCookies: () => string) => {
  const httpLink = new HttpLink({
    uri: adjustedUri,
    headers: {
      Cookie: getCookies(),
    },
    credentials: "include",
  });

  const additiveLink = from([errorLink, httpLink]);
  return additiveLink;
};

const createInMemoryCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          posts: relayStylePagination(),
          workspaces: {
            keyArgs: false,
            merge(existing = { list: [] }, incoming) {
              const newList = [...existing.list, ...incoming.list];
              return {
                ...incoming,
                list: newList,
              };
            },
          },
          booking: {
            read(_, { args, toReference }) {
              return toReference({
                id: args.bookingId,
                __typename: "Booking",
              });
            },
          },
          coworkers: {
            keyArgs: false,
            merge: (existing = { hasMore: false, list: [] }, incoming) => {
              return {
                ...incoming,
                list: [...existing.list, ...(incoming?.list ?? [])],
              };
            },
          },
          getBookingRequest: {
            read(_, { args, toReference }) {
              return toReference({
                id: args.bookingRequestId,
                __typename: "BookingRequestDecorated",
              });
            },
          },
          bookings: {
            keyArgs: false,
            merge: (existing = { bookings: [] }, incoming) => {
              return {
                ...incoming,
                bookings: [...existing.bookings, ...(incoming?.bookings ?? [])],
              };
            },
          },
          availableWorkspaces: {
            keyArgs: false,
            merge: (existing = { workspaces: [] }, incoming) => {
              return {
                ...incoming,
                workspaces: [
                  ...existing.workspaces,
                  ...(incoming?.workspaces ?? []),
                ],
              };
            },
          },
        },
      },
      Address: {
        merge: true,
      },
      TeamMember: {
        keyFields: ["id", "isAdmin", "isOwner"],
      },
      Resource: {
        fields: {
          rangeBookingUnitList: {
            read(
              rangeBookingUnitList: RangeBookingUnit[],
              { readField },
            ): RangeBookingUnit[] {
              if (!rangeBookingUnitList || rangeBookingUnitList.length === 0) {
                return rangeBookingUnitList;
              }

              const availability: ResourceAvailability =
                readField("availability");

              if (
                !availability?.dayOccupation ||
                availability.dayOccupation.length === 0
              ) {
                return rangeBookingUnitList.map((range: RangeBookingUnit) => ({
                  ...range,
                  isUnavailable: null,
                }));
              }

              const occupations = dayOccupationWithIntervals(
                availability?.dayOccupation,
              );

              const day = occupations[0].interval.day;
              const result = rangeBookingUnitList.map(
                (range: RangeBookingUnit) => {
                  const time: TheTime = new TheTime(
                    range.startTime.hour,
                    range.startTime.minute,
                  );
                  const dayTime: TheDateTime = new TheDateTime(day, time);
                  const rangeInterval = TheInterval.fromDuration(
                    dayTime.toString(),
                    range.durationInMinutes,
                  );

                  const intersections =
                    rangeInterval.getIntersections(occupations);

                  const newRange = {
                    ...range,
                    isUnavailable: intersections.some(
                      (occupation) => occupation.status !== "AVAILABLE",
                    ),
                  };

                  return newRange;
                },
              );

              return result;
            },
          },
          usedUpCapacity: {
            read(_: any, { variables, readField }: any) {
              if (!variables?.date) {
                return 0;
              }

              const availability: ResourceAvailability =
                readField("availability");

              if (!availability?.dayOccupation) {
                return 0;
              }

              const date: TheDay = TheDay.parse(variables.date);
              const result = getUsedUpCapacity(
                availability?.dayOccupation,
                date,
                variables.time,
              );

              return result;
            },
          },
        },
      },
    },
  });
};

export async function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any,
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const store = useMemo(
    () => initializeClientSideApollo(pageProps),
    [pageProps],
  );
  return store;
}
