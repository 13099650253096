import classNames from 'classnames';
import {
  useEffect,
  useMemo,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import type { Div } from '~/global';
import { useBrowser } from '~/hooks/useBrowser';
import { useLazyComponent } from '~/hooks/useLazyComponent';
import type { PhoneMockupProps } from '../components/PhoneMockup';

const PHONE_WIDTH = 392;
const PHONE_HEIGHT = 844;

interface AppLayoutProps extends Div {
  children?: ReactNode | ReactNode[];
  navigation?: ReactNode | ReactNode[];
  override?: {
    viewport?: Div;
  };
}

export const AppLayout: FunctionComponent<AppLayoutProps> = ({
  className,
  children,
  override,
}) => {
  const { isDesktop } = useBrowser();
  const [phoneScale, setPhoneScale] = useState<number>(1);

  const LazyPhoneMockup = useLazyComponent<PhoneMockupProps>(() =>
    import('~/components/PhoneMockup').then((module) => module.PhoneMockup),
  );

  useEffect(() => {
    const maxHeight = Math.min(window.innerHeight * 0.9, PHONE_HEIGHT);
    setPhoneScale(maxHeight / PHONE_HEIGHT);
  }, [setPhoneScale]);

  const { className: viewportClassName, ...viewportProps } =
    override?.viewport ?? {};

  const clxViewport = classNames(
    'transform h-full mx-auto overflow-hidden',
    {
      'flex items-center justify-center': isDesktop,
    },
    viewportClassName,
  );

  const clxLayout = classNames(
    'relative',
    'flex flex-col',
    'h-full',
    'overflow-hidden',
    'bg-base-100',
    'select-none',
    className,
  );

  const content = useMemo(
    () => (
      <div id="layout" className={clxLayout}>
        {children}
      </div>
    ),
    [children, clxLayout],
  );

  if (isDesktop === null) return null;

  return (
    <div id="viewport" className={clxViewport} {...viewportProps}>
      {isDesktop ? (
        <LazyPhoneMockup
          phoneScale={phoneScale}
          phoneHeight={PHONE_HEIGHT}
          phoneWidth={PHONE_WIDTH}
        >
          {content}
        </LazyPhoneMockup>
      ) : (
        content
      )}
    </div>
  );
};
