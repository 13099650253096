import classNames from 'classnames';
import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

type Div = JSX.IntrinsicElements['div'];
export interface LoaderProps extends Div {
  override?: {
    icon?: React.SVGAttributes<SVGElement>;
  };
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  override,
  ...props
}) => {
  const { className: iconClassName, ...iconProps } = override?.icon ?? {};

  const clxIcon = classNames('animate-spin', 'w-8', 'h-8', iconClassName);

  const clxLoader = classNames(
    'flex',
    'justify-center',
    'items-center',
    className,
  );

  return (
    <div className={clxLoader} {...props}>
      <BiLoaderAlt className={clxIcon} {...iconProps} />
    </div>
  );
};
