import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';

interface FetchSettings {
  extraHeaders?: { [key: string]: string };
  addExtraHeader: (key: string, value: string) => void;
}

interface AppContextType {
  fetchSettings: FetchSettings;
}

const AppContext = createContext<AppContextType>({} as AppContextType);

/**
 * AppProvider to be used for the highest level shared state in the application.
 */
export const AppProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [extraFetchHeaders, setExtraFetchHeaders] = useState<{
    [key: string]: string;
  }>({});

  const addExtraHeader = (key: string, value: string) => {
    setExtraFetchHeaders({ ...extraFetchHeaders, ...{ [key]: value } });
  };

  return (
    <AppContext.Provider
      value={{
        fetchSettings: {
          addExtraHeader,
          extraHeaders: extraFetchHeaders,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext(): AppContextType {
  const context = useContext(AppContext);
  return context;
}
