import { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";

export const useCommonDict = () => {
  const { t, lang } = useTranslation("common");

  return useMemo(() => {
    return { t, language: lang };
  }, [t]);
};

export const useDiscoveryDict = () => {
  const { t } = useTranslation("discovery");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useDetailsDict = () => {
  const { t } = useTranslation("details");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useBookingsDict = () => {
  const { t } = useTranslation("bookings");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useOnboardingDict = () => {
  const { t } = useTranslation("onboarding");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useComponentsDict = () => {
  const { t } = useTranslation("components");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useWelcomeDict = () => {
  const { t } = useTranslation("welcome");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useSearchDict = () => {
  const { t } = useTranslation("search");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};

export const useCommunityDict = () => {
  const { t } = useTranslation("community");
  const { t: tc, language } = useCommonDict();

  return useMemo(() => {
    return { t, tc, language };
  }, [t, tc, language]);
};
