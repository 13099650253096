import classNames from 'classnames';
import type { ForwardedRef, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';
import type { Div } from '~/global';

const Component: ForwardRefRenderFunction<HTMLElement, Div> = (
  { className, children, ...props },
  ref,
) => {
  const clx = classNames('relative', 'w-full', 'h-full', 'bg-white', className);

  return (
    <div className={clx} ref={ref as ForwardedRef<HTMLDivElement>} {...props}>
      <div className="w-full h-full flex items-center justify-center bg-slate-100 animate-pulse">
        <div className="w-full h-full bg-slate-300 rounded-lg absolute top-0 left-0 animate-ripple" />
        {children}
      </div>
    </div>
  );
};

export const Ripple = forwardRef(Component);
