import { Loader } from '~/components/loader/Loader';
import type { DynamicOptions } from 'next/dynamic';
import dynamic from 'next/dynamic';
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ComponentType,
  type MutableRefObject,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import { Ripple } from '~/components/facades/Ripple';

export type ComponentImport<TProps = {}> = () => Promise<
  ComponentType<PropsWithChildren<TProps>>
>;

export interface LazyComponentOptions<TProps> {
  dynamic?: DynamicOptions<TProps>;
  observer?: IntersectionObserverInit;
  facade?: ReactElement;
  event?: keyof HTMLElementEventMap | 'intersection';
  ref?: MutableRefObject<HTMLElement>;
}

export const useLazyComponent = <TProps,>(
  importer: ComponentImport<TProps>,
  options?: LazyComponentOptions<TProps>,
) => {
  const [_importer_] = useState(() => importer);
  const [_options_] = useState(options);

  const ref = useRef<HTMLElement>(null);

  const [isEventTriggered, setEventTriggered] = useState(false);
  const [isComponentLoaded, setComponentLoaded] = useState(false);

  const isServerSide = typeof window === 'undefined';

  const {
    facade,
    event,
    dynamic: dynamicOptions,
    observer: observerOptions,
  } = _options_ ?? {};

  const Component = useMemo(
    () =>
      dynamic<PropsWithChildren<TProps>>(
        async () => {
          return isEventTriggered
            ? await _importer_()
                .then((component) => {
                  setComponentLoaded(true);
                  return component;
                })
                .catch((reason) => {
                  console.debug(reason);
                  return null;
                })
            : null;
        },
        { ssr: false, ...dynamicOptions },
      ),
    [_importer_, dynamicOptions, isEventTriggered],
  );

  const onEventTriggered = useCallback(() => {
    setEventTriggered(true);
  }, [setEventTriggered]);

  const observer = useMemo(() => {
    if (isServerSide) return;

    return new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        onEventTriggered();
      }
    }, observerOptions);
  }, [isServerSide, observerOptions, onEventTriggered]);

  useEffect(() => {
    if (isServerSide) return;

    const current = _options_?.ref ? _options_.ref.current : ref.current;
    const useIntersectionObserver = !event || event === 'intersection';

    if (current) {
      if (useIntersectionObserver) {
        observer?.observe(current);
      } else {
        current.addEventListener(event, onEventTriggered);
      }
    }

    return () => {
      if (current) {
        if (useIntersectionObserver) {
          observer?.unobserve(current);
        } else {
          current.removeEventListener(event, onEventTriggered);
        }
      }
    };
  });

  const Facade = useMemo(() => {
    if (isServerSide) return null;

    return cloneElement(
      facade ?? (
        <Ripple>
          <Loader />
        </Ripple>
      ),
      { ref },
    );
  }, [facade, isServerSide]);

  const LazyComponent = useCallback(
    (props: PropsWithChildren<TProps>) => {
      return (
        <>
          {!isComponentLoaded && Facade}
          {!isServerSide && <Component {...props} />}
        </>
      );
    },
    [Component, Facade, isComponentLoaded, isServerSide],
  );

  return LazyComponent;
};
