module.exports = {
  locales: ["en"],
  defaultLocale: "en",
  pages: {
    "*": ["common", "components"],
    "/book": ["search", "discovery", "details"],
    "/home": ["community", "bookings", "details", "search"],
    "/home/past-bookings": ["bookings", "details", "search"],
    "/search/wizard": ["discovery", "search"],
    "/search": ["discovery", "search", "details"],
    "/workspace/[workspaceId]": ["details"],
    "/onboarding": ["onboarding"],
    "/welcome": ["welcome"],
    "/saved": ["details"],
    "/contact-request-sent": ["search"],
    "/community": ["community", "bookings", "details", "search"],
    "rgx:^/community/network/": ["community"],
    "/home/account/privacy": ["community"],
    "/profile/[profileId]": ["community", "bookings", "details", "search"],
  },
  loadLocaleFrom: (lang, ns) => import(`./public/locales/${lang}/${ns}.json`),
};
