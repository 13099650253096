import Head from "next/head";
import { useNavigator } from "~/hooks/navigator/useNavigator";
import { config } from "~/global.config";

interface SeoMetaTagsProps {
  description?: string;
  image?: any;
  title?: string;
  url?: string;
}

export const SeoMetaTags: React.FC<SeoMetaTagsProps> = (props) => {
  const { pathname } = useNavigator();

  const url = props.url || config.origin + pathname;

  const description =
    props.description ||
    "Workspaces as a service. For remote and hybrid teams.";

  const { image } = props;

  const imageUrl = image?.url || `${config.origin}/guest/images/seo-image.png`;
  const imageWidth = image?.width || 1200;
  const imageHeight = image?.height || 630;

  const title = props.title || "Flowpass";

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} />

      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:title" content={title} />
      <meta property="twitter:domain" content="app.flowpass.co" />
      <meta property="twitter:url" content={url} />
    </Head>
  );
};
